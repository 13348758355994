import { template as template_584a2e2a5da94f999b187ffe3d85949e } from "@ember/template-compiler";
const FKText = template_584a2e2a5da94f999b187ffe3d85949e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
