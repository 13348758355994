import { template as template_c4c8707579dd45b6a0a630d994a2b1d2 } from "@ember/template-compiler";
const FKLabel = template_c4c8707579dd45b6a0a630d994a2b1d2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
